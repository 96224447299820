<template>
    <div class="min-h-15 min-w-full">

        <div class="w-full ">

            <div id="accordion-collapse{{content.data.text}}" data-accordion="collapse"  data-active-classes="text-orange" data-inactive-classes="bg-orange">
                <h2 id="accordion-collapse-heading-{{content.data.text}}"     >
                    <button type="button" class="flex items-center justify-between p-5 w-full border  border-gray-200 background-custom-blue"
                            data-accordion-target="#accordion-collapse-body-{{content.data.text}}"
                            aria-expanded="true" aria-controls="accordion-collapse-body-{{content.data.text}}">
                        <span class=" hover:text-gray-800 text-left "   v-html="content.data.name"></span>
                        <svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>

                </h2>
                <div id="accordion-collapse-body-{{content.data.text}}"  aria-labelledby="accordion-collapse-heading-{{content.data.text}}" >
                    <div class="p-5  text-left rounded rounded-t-none border shadow-lg border-t-0"   v-html="content.data.text">

                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

export default {
    emits: ["clicked"], //
    name: "ContentAccordion",
    components: {

    },
    props: {
        content: Object,
        contents: Object,
        editable:Boolean
    },
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>

